<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction, postAction } from '@/command/netTool'
import FormUploadSku from '@/components/FormView/FormUploadSku'

export default {
  name: 'mainBackDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: { templateId: '', showName: '', showSpecialType: '', couponList: [] },
      // 区域类型
      cascadeData: [],
      mainId: null,
      coupons: [],
    }
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      getAction('/farmShowSpecial/detail?id=' + id).then((res) => {
        this.detail = res.data
      })
    }
    getAction('/base/farmCouponRule/relationList', { giveType: 1 }).then((res) => {
      this.coupons = res.data
    })
  },
  methods: {
    getForm1() {
      const this_ = this
      return [
        {
          name: '专题名称',
          key: 'showName',
          labelAlign: 'right',
          type: 'input',
          display: true,
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
        {
          name: '选择模式',
          key: 'showSpecialType',
          type: 'radio',
          display: true,
          onChange: () => {
            this.detail.templateId = ''
          },
          typeData: [
            {
              name: '专题页模版',
              value: '0',
            },
            {
              name: '富文本自定义',
              value: '1',
            },
          ],
        },
        {
          name: '专题模板',
          key: 'templateId',
          labelAlign: 'right',
          type: 'actionUploadTemplate',
          is_show: true,
          value: this.detail.templateId,
          display: true,
          filter: (item) => item.showSpecialType == this.detail.showSpecialType,
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          name: '联系电话',
          key: 'linkPhone',
          type: 'input',
          display: this.detail.showSpecialType == '1',
        },
        {
          type: 'rich',
          key: 'richText',
          name: '富文本内容',
          display: this.detail.showSpecialType == '1',
        },
        {
          name: '底纹',
          type: 'iconUpload',
          key: 'a1',
          display: this.detail.showSpecialType == '0',
          typeData: [
            {
              maxLength: 1,
              key: 'backgroundImg',
              desc: '宽<750px 图片平铺 <br/> @2x  xhdpi',
            },
          ],
        },
        {
          name: 'Banner图',
          type: 'iconUpload',
          key: 'a1',
          display: this.detail.showSpecialType == '0',
          typeData: [
            {
              maxLength: 1,
              key: 'bannerImg',
              desc: '宽度750PX <br/> @2x  xhdpi',
            },
          ],
        },
        // ...this.getForm3(),
        {
          name: '优惠券',
          type: 'table',
          showFootButton: true,
          showRowSelect: false,
          showPagination: false,
          unShowEditBtn: true,
          key: 'couponList',
          display: this.detail.showSpecialType == '0',
          onAddData: () => {
            return {
              couponPreviewUrl: '',
              couponRuleId: null,
            }
          },
          columns: [
            {
              title: '图片',
              // type: 'lt-40',
              dataIndex: 'couponPreviewUrl',
              customRender: (text, records, index, h) => {
                return (
                  <FormUploadSku
                    value={text}
                    onChange={(data) => {
                      records.couponPreviewUrl = data
                    }}
                  />
                )
              },
            },
            {
              dataIndex: 'couponRuleId',
              align: 'left',
              title: '优惠券',
              customRender: function (text, records, index) {
                const onInput = (value) => {
                  records.couponRuleId = value
                }
                return (
                  <div style={{ width: '300px' }}>
                    <a-select placeholder="优惠券" style={{ width: '100%' }} onChange={onInput} value={text}>
                      {this_.coupons.map((d) => {
                        return (
                          <a-select-option value={d.id} key={d.id}>
                            {d.couponName}
                          </a-select-option>
                        )
                      })}
                    </a-select>
                  </div>
                )
              },
            },
            {
              dataIndex: 'a10',
              align: 'center',
              width: '10%',
              title: '操作',
              unShowEditBtn: true,
              tableType: 'editDelete',
            },
          ],
          dataSource: this.detail.couponList,
        },
        {
          name: '底部图',
          type: 'iconUpload',
          key: 'a1',
          display: this.detail.showSpecialType == '0',
          typeData: [
            {
              maxLength: 1,
              key: 'bottomImg',
              desc: '宽度750PX <br/> @2x  xhdpi',
            },
          ],
        },
        {
          name: '返回图标',
          type: 'iconUpload',
          key: 'a1',
          display: this.detail.showSpecialType == '0',
          typeData: [
            {
              maxLength: 1,
              key: 'backImg',
              desc: '宽度750PX <br/> @2x  xhdpi',
            },
          ],
        },
        {
          name: 'Banner图高度(px)',
          key: 'bannerHeight',
          labelAlign: 'right',
          type: 'input',
          display: this.detail.showSpecialType == '0',
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
      ].filter((e) => e.display)
    },
    getFoot() {
      const that = this
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            const success = () => {
              this.$router.back()
            }
            let { templateId, showSpecialType } = data
            if (showSpecialType == '1') {
              api.command[data.id ? 'edit' : 'create']
                .call(that, {
                  url: '/farmShowSpecial',
                  isPost: false,
                  params: {
                    ...data,
                    couponList: this.detail.couponList,
                    templateId,
                  },
                })
                .then(success)
              return
            }
            let aVal = templateId
            if (!data.showName && !data.bannerHeight && !aVal) {
              this.$message.error('请填写专题名称/Banner图高度/专题模板')
              return
            } else if (/^[0-9]*$/.test(data.bannerHeight) === false) {
              this.$message.error('Banner图高度必须为数字')
              return
            } else {
              api.command[data.id ? 'edit' : 'create']
                .call(that, {
                  url: '/farmShowSpecial',
                  isPost: false,
                  params: {
                    ...data,
                    couponList: this.detail.couponList,
                    templateId: aVal,
                  },
                })
                .then(success)
            }
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.back()
          },
        },
      ]

      return {
        left,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[{ type: 'cardForm', data: this.getForm1(), layout: 'horizontal' }]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
